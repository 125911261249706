import * as React from "react"
import Layout from "../components/layout"

const PrivacyPage = () => {
  return (
    <Layout pageTitle="Privacy Policy">
      <article className="nomal-page">
        <h1>Privacy Policy</h1>
        <section>
          <br/>
          <p>Horiko Capital Management LLC, 以下「当社」）は個人情報の保護に最大限の努力を行っています。当社は、以下の方針に記載する以外の方法で、顧客（ウェブサイト利用者を含む）の個人情報を利用したり、第三者に提供することはありません。</p>
          <br/>
          <p>当社は主に下記内容の情報を収集する事があります。</p>
          <ul>
            <li>本ウェブサイトを通じて、又は電話や他のコミュニケーション手段を通じて提出された個人情報</li>
            <li>顧客の取引記録や口座残高に関わる情報</li>
          </ul>
          <br/>
          <p>当社は顧客の個人情報を有償無償を問わず、第三者に譲渡しません。必要な範囲で外部サービスを利用する場合がありますが、当該サービス提供者に対し、サービス提供に関連して知り得た個人情報の秘密を保持することを内容とする、厳格な守秘義務を課しています。</p>
          <br/>
          <p>当社は、利用者及び顧客の個人情報を、司法当局・規制当局に、適切な場合には、適切な限度で、個人情報を開示することがあります。また当社は、信用審査、権利の実現、債権回収、その他当社の権利および資産の保護のため、顧客の個人情報を開示することがあります。</p>
          <br/>
          <p>当社の権限ある社員・代理人のみが顧客の個人情報にアクセスすることができます。当社の全社員、サービス提供者、提携パートナーはすべて厳格なプライバシー保護基準に従うことを義務づけられており、また、守秘義務を負っています。</p>
          <br />
          <p>
            顧客と当社との関係が終了した後においても、本プライバシー・ポリシーに従い個人情報を取扱います。
          </p>
        </section>
      </article>
    </Layout>
  )
}

export default PrivacyPage
